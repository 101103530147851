<template>
  <b-table
    centered
    responsive
    borderless
    class="mb-0"
    thead-class="text-dark align-middle text-nowrap text-right border-0"
    tbody-class="text-dark align-middle text-nowrap text-right border-0"
    :fields="['passenger', 'itinerary', 'services', 'amount', 'total']"
    :items="resolveSsrData(reservationsData)"
  >
    <template
      v-for="(column, indexColumn) in ['passenger', 'itinerary', 'services', 'amount', 'total']"
      v-slot:[`head(${column})`]="data"
    >
      <span
        :key="indexColumn"
        contenteditable
      >
        {{ $t(`reservation.${data.column}`) }}
      </span>
    </template>

    <template #table-colgroup="scope">
      <col
        v-for="field in scope.fields"
        :key="field.key"
        :style="{
          width: field.key === 'seat' ? '120px' : '180px',
          'max-width': field.key === 'seat' ? '120px' : '400px',
        }"
      >
    </template>

    <template #cell(passenger)="{item}">
      <span contenteditable>
        {{ item.passenger.lastName }} / {{ item.passenger.firstName }} {{ item.passenger.title || '' }}
      </span>
    </template>

    <template #cell(itinerary)="{item}">
      <div v-if="!isEmpty(item.segments) && item.segments.length < 2">
        <span contenteditable>
          {{ item.segments[0].departure.iataCode }} - {{ item.segments[0].arrival.iataCode }}
        </span>
      </div>

      <div v-if="!isEmpty(item.segments) && item.segments.length > 1">
        <span
          v-for="(segment, indexSegment) in item.segments"
          :key="indexSegment"
          contenteditable
        >
          {{ (indexSegment === 0) ? `${segment.departure.iataCode}` : '' }}
          {{ (indexSegment !== 0) && (indexSegment !== item.segments.length - 1) ? ` - ${segment.departure.iataCode}` : ` - ${segment.arrival.iataCode}` }}
        </span>
      </div>

    </template>

    <template #cell(services)="{item}">
      <span contenteditable>{{ $t(`reservation.${item.serviceType.toLowerCase()}`) }}</span>
    </template>

    <template #cell(amount)="{item}">
      <span
        v-if="item.serviceType === 'SEAT'"
        contenteditable
      >
        {{ item.pdcSeat }}
      </span>

      <span
        v-if="item.serviceType === 'BAGGAGE'"
        contenteditable
      >
        {{ (resolveSsrNameByCode(['VN1A'].includes(reservationsData.source) ? item.serviceCode : item.serviceSubCode) ) }} x{{ item.numberOfItems }}
      </span>
    </template>

    <template #cell(total)="{item}">
      <div
        class="text-right font-weight-bolder"
        style="margin-right: -4px;"
      >
        <IAmInputMoney
          :value-money="item.totalPrice"
          placeholder=""
          :disable-class="true"
          :can-input-negative="true"
          :on-blur="(text) => onChange(text, item.id)"
        />
      </div>
    </template>
  </b-table>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  VBTooltip,
  BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { ref, watch } from '@vue/composition-api'
import { isEmpty } from 'lodash'

import IAmInputMoney from '@/components/IAmInputMoney.vue'
import { resolveSsrNameByCode } from '@/constants/selectOptions'

import { formatCurrency, dateTime } from '@core/utils/filter'
// import useToast from '@useToast'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BTable,
    IAmInputMoney,
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const {
      STATUS_NAME_ANCILLARY,
      resolveSsrData,
    } = useReservationHandle()

    return {
      resolveSsrData,
      dateTime,
      formatCurrency,
      resolveSsrNameByCode,
      STATUS_NAME_ANCILLARY,
      isEmpty,
    }
  },
}
</script>
