<template>
  <svg
    width="13"
    height="18"
    viewBox="0 0 13 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 0.202966C5.10806 0.189183 3.52797 0.863622 2.35631 2.07835C1.18465 3.29308 0.517086 4.94892 0.500037 6.68267C0.49699 7.5943 0.683683 8.49549 1.04691 9.32251C2.52471 12.3334 4.35196 15.1382 6.48646 17.6721C6.5195 17.7115 6.56007 17.743 6.6055 17.7646C6.65093 17.7862 6.70018 17.7974 6.75 17.7974C6.79982 17.7974 6.84907 17.7862 6.8945 17.7646C6.93993 17.743 6.9805 17.7115 7.01354 17.6721C9.14724 15.1412 10.9745 12.3398 12.4531 9.33242C12.8164 8.50542 13.0031 7.60421 13 6.69258C12.9854 4.95709 12.3189 3.29872 11.147 2.08185C9.97505 0.864991 8.39355 0.189195 6.75 0.202966ZM6.75 10.0474C5.89703 10.0547 5.07614 9.70444 4.46744 9.07345C3.85874 8.44245 3.51194 7.58224 3.50314 6.68157C3.50314 5.77228 3.84522 4.90023 4.45413 4.25726C5.06303 3.6143 5.88888 3.25308 6.75 3.25308C7.61112 3.25308 8.43697 3.6143 9.04587 4.25726C9.65478 4.90023 9.99685 5.77228 9.99685 6.68157C9.98805 7.58224 9.64126 8.44245 9.03256 9.07345C8.42386 9.70444 7.60297 10.0547 6.75 10.0474Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconArrival',
}
</script>
