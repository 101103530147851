var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "centered": "",
      "responsive": "",
      "borderless": "",
      "thead-class": "text-dark align-middle text-nowrap text-right border-0",
      "tbody-class": "text-dark align-middle text-nowrap text-right border-0",
      "fields": ['passenger', 'itinerary', 'services', 'amount', 'total'],
      "items": _vm.resolveSsrData(_vm.reservationsData)
    },
    scopedSlots: _vm._u([_vm._l(['passenger', 'itinerary', 'services', 'amount', 'total'], function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: indexColumn,
            attrs: {
              "contenteditable": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "table-colgroup",
      fn: function fn(scope) {
        return _vm._l(scope.fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key === 'seat' ? '120px' : '180px',
              'max-width': field.key === 'seat' ? '120px' : '400px'
            }
          });
        });
      }
    }, {
      key: "cell(passenger)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          attrs: {
            "contenteditable": ""
          }
        }, [_vm._v(" " + _vm._s(item.passenger.lastName) + " / " + _vm._s(item.passenger.firstName) + " " + _vm._s(item.passenger.title || '') + " ")])];
      }
    }, {
      key: "cell(itinerary)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [!_vm.isEmpty(item.segments) && item.segments.length < 2 ? _c('div', [_c('span', {
          attrs: {
            "contenteditable": ""
          }
        }, [_vm._v(" " + _vm._s(item.segments[0].departure.iataCode) + " - " + _vm._s(item.segments[0].arrival.iataCode) + " ")])]) : _vm._e(), !_vm.isEmpty(item.segments) && item.segments.length > 1 ? _c('div', _vm._l(item.segments, function (segment, indexSegment) {
          return _c('span', {
            key: indexSegment,
            attrs: {
              "contenteditable": ""
            }
          }, [_vm._v(" " + _vm._s(indexSegment === 0 ? "".concat(segment.departure.iataCode) : '') + " " + _vm._s(indexSegment !== 0 && indexSegment !== item.segments.length - 1 ? " - ".concat(segment.departure.iataCode) : " - ".concat(segment.arrival.iataCode)) + " ")]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell(services)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          attrs: {
            "contenteditable": ""
          }
        }, [_vm._v(_vm._s(_vm.$t("reservation.".concat(item.serviceType.toLowerCase()))))])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.serviceType === 'SEAT' ? _c('span', {
          attrs: {
            "contenteditable": ""
          }
        }, [_vm._v(" " + _vm._s(item.pdcSeat) + " ")]) : _vm._e(), item.serviceType === 'BAGGAGE' ? _c('span', {
          attrs: {
            "contenteditable": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.resolveSsrNameByCode(['VN1A'].includes(_vm.reservationsData.source) ? item.serviceCode : item.serviceSubCode)) + " x" + _vm._s(item.numberOfItems) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-right font-weight-bolder",
          staticStyle: {
            "margin-right": "-4px"
          }
        }, [_c('IAmInputMoney', {
          attrs: {
            "value-money": item.totalPrice,
            "placeholder": "",
            "disable-class": true,
            "can-input-negative": true,
            "on-blur": function onBlur(text) {
              return _vm.onChange(text, item.id);
            }
          }
        })], 1)];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }