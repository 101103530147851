var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "e-ticket d-flex flex-column bg-white mx-auto",
    class: [_vm.printSize, "theme-".concat(_vm.currentTheme), {
      'border border-theme': _vm.frontendI18n
    }, {
      'showBackgroundColor': _vm.config.showBackgroundColor
    }]
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.config.showAgency,
      expression: "config.showAgency"
    }],
    staticClass: "section-agency"
  }, [_c('div', {
    staticStyle: {
      "flex": "1",
      "position": "relative"
    }
  }, [_vm.config.locale === 'vi' ? _c('div', [_vm.config.useQuillEditor ? _c('quill-editor', {
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.config.agencyInfo,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "agencyInfo", $$v);
      },
      expression: "config.agencyInfo"
    }
  }) : _vm.config.agencyInfo ? _c('div', {
    attrs: {
      "contenteditable": "true"
    },
    on: {
      "input": function input($event) {
        _vm.config.agencyInfo = $event.target.innerHTML;
      }
    }
  }, [_vm._m(0)]) : _c('div', {
    attrs: {
      "contenteditable": "true"
    },
    on: {
      "input": function input($event) {
        _vm.config.agencyInfo = $event.target.innerHTML;
      }
    }
  }, [_vm.agencyInfo ? _c('div', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.agencyInfo.agencyName))])]), _c('div', [_vm._v(" Địa chỉ: " + _vm._s(_vm.agencyInfo.agencyAddress) + " ")]), _c('div', [_vm._v(" Hotline: " + _vm._s(_vm.agencyInfo.ownerPhoneNumber) + " ")])]) : _vm._e()])], 1) : _vm.config.locale === 'en' ? _c('div', [_vm.config.useQuillEditor ? _c('quill-editor', {
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.config.agencyInfoEn,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "agencyInfoEn", $$v);
      },
      expression: "config.agencyInfoEn"
    }
  }) : _vm.config.agencyInfoEn ? _c('div', {
    attrs: {
      "contenteditable": "true"
    },
    on: {
      "input": function input($event) {
        _vm.config.agencyInfoEn = $event.target.innerHTML;
      }
    }
  }, [_vm._m(1)]) : _c('div', {
    attrs: {
      "contenteditable": "true"
    },
    on: {
      "input": function input($event) {
        _vm.config.agencyInfoEn = $event.target.innerHTML;
      }
    }
  }, [_vm.agencyInfoEn ? _c('div', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.agencyInfoEn.agencyName))])]), _c('div', [_vm._v(" Address: " + _vm._s(_vm.agencyInfoEn.agencyAddress) + " ")]), _c('div', [_vm._v(" Hotline: " + _vm._s(_vm.agencyInfoEn.ownerPhoneNumber) + " ")])]) : _vm._e()])], 1) : _vm._e(), _vm.isConfig && (_vm.config.agencyInfo || _vm.config.agencyInfoEn) ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger.window",
      modifiers: {
        "hover": true,
        "v-danger": true,
        "window": true
      }
    }],
    staticClass: "p-1 rounded-circle",
    staticStyle: {
      "position": "absolute",
      "right": "0",
      "top": "0"
    },
    attrs: {
      "title": "Đặt lại thông tin đại lý về mặc định",
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.handleResetAgencyInfo
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "RotateCwIcon",
      "size": "20"
    }
  })], 1) : _vm._e()], 1), _vm.agencyAvatar && _vm.config.showAgencyLogo ? _c('b-img', {
    staticStyle: {
      "height": "80px"
    },
    attrs: {
      "src": _vm.agencyAvatar
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex-center justify-content-between text-20px fw-700 px-20px"
  }, [_c('div', {
    staticClass: "color-theme text-uppercase",
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.eTicket', _vm.config.locale)) + " ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.config.showTimeHold,
      expression: "config.showTimeHold"
    }],
    staticClass: "text-red text-14px font-weight-bolder"
  }, [_vm.frontendI18n ? [_vm._v(" " + _vm._s(_vm.$t('ticket.prepaymentNote', _vm.config.locale)) + " ")] : _vm.config.status.toUpperCase() !== 'PAID' ? [_c('div', {
    attrs: {
      "contenteditable": "true"
    },
    on: {
      "input": function input($event) {
        _vm.config.timeHoldNote = $event.target.textContent.trim();
      }
    }
  }, [_vm.timeHold ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('ticket.payBefore', _vm.config.locale)) + ": " + _vm._s(_vm.timeHold) + " ")]) : _vm._e()])] : _vm._e()], 2)]), _c('div', {
    staticClass: "d-flex-center gap-2"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('div', {
    staticClass: "section-table pax-table-template-columns",
    class: [{
      'border-theme': !_vm.config.showBackgroundColor
    }]
  }, [_c('div', {
    staticClass: "bg-theme fw-700 d-flex align-items-center p-4px-16px",
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.no', _vm.config.locale)) + " ")]), _c('div', {
    staticClass: "bg-theme fw-700 d-flex align-items-center p-4px-16px",
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.passenger', _vm.config.locale)) + " ")]), _c('div', {
    staticClass: "bg-theme fw-700 d-flex align-items-center p-4px-16px",
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.additionalServices', _vm.config.locale)) + " ")]), _vm._l(_vm.newPaxs, function (item, index) {
    var _item$passenger, _item$passenger2;
    return [_c('div', {
      key: "no-".concat(index),
      staticClass: "p-4px-16px d-flex align-items-center w-100 border-t"
    }, [_c('div', {
      attrs: {
        "contenteditable": "true"
      }
    }, [_vm._v(" " + _vm._s(String(index + 1).padStart(2, "0")) + " ")])]), _c('div', {
      key: "pax-".concat(index),
      staticClass: "p-4px-16px d-flex align-items-center w-100 border-t"
    }, [_c('div', {
      staticStyle: {
        "width": "100%",
        "font-weight": "700"
      },
      attrs: {
        "contenteditable": "true"
      }
    }, [!_vm.frontendI18n && item !== null && item !== void 0 && (_item$passenger = item.passenger) !== null && _item$passenger !== void 0 && _item$passenger.paxType && item !== null && item !== void 0 && (_item$passenger2 = item.passenger) !== null && _item$passenger2 !== void 0 && _item$passenger2.title ? [_vm._v(" (" + _vm._s(item.passenger.title && _vm.$te("reservation.titleOptions.".concat(item.passenger.paxType, ".").concat(item.passenger.title), _vm.config.locale) ? _vm.$t("reservation.titleOptions.".concat(item.passenger.paxType, ".").concat(item.passenger.title), _vm.config.locale) : item.passenger.title) + ") ")] : _vm._e(), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(" " + _vm._s(_vm.frontendI18n && !_vm.isEmpty(item.passenger) ? _vm.$t(item.passenger) : _vm.getLocaleFullName(item.passenger)) + " ")])], 2)]), _c('div', {
      key: "bag-".concat(index),
      staticClass: "p-4px-16px d-flex align-items-center w-100 gap-1 border-t"
    }, [_c('div', {
      staticClass: "flex-1",
      attrs: {
        "contenteditable": "true"
      }
    }, [_vm.frontendI18n ? [_vm._v(" " + _vm._s(_vm.$t(item.bags, _vm.config.locale)) + " ")] : item.bags && item.bags.length ? [_vm._l(item.bags, function (bag, bagIndex) {
      return [bag.xBagName && bag.trip ? _c('div', {
        key: "BAG-".concat(bagIndex, "-").concat(bag.segmentIds[0], "-").concat(bag.paxId, "-").concat(bag.id)
      }, [_vm._v(" " + _vm._s(bag.trip) + ": " + _vm._s(bag.xBagName) + " ")]) : _vm._e()];
    })] : _vm._e(), item.others && item.others.length ? [_vm._l(item.others, function (other, otherIndex) {
      return [other.trip && other.otherName ? _c('div', {
        key: "OTHER-".concat(otherIndex, "-").concat(other.segmentIds[0], "-").concat(other.paxId, "-").concat(other.serviceSubCode, "-").concat(other.id)
      }, [_vm._v(" " + _vm._s(other.trip) + ": " + _vm._s(other.otherName) + " ")]) : _vm._e()];
    })] : _vm._e(), item.seat && item.seat.length ? [_vm._l(item.seat, function (seat, seatIndex) {
      return [seat.seatName ? _c('div', {
        key: "seat-".concat(seatIndex, "-").concat(seat.segmentIds[0], "-").concat(seat.paxId, "-").concat(seat.id, "-").concat(seat.pdcSeat)
      }, [_vm._v(" " + _vm._s(seat.trip) + ": " + _vm._s(seat.seatName) + " ")]) : _vm._e()];
    })] : _vm._e()], 2), _c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removePax(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "MinusIcon",
        "size": "16"
      }
    })], 1), _c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-success"
      },
      on: {
        "click": function click($event) {
          return _vm.addPax(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon",
        "size": "16"
      }
    })], 1)], 1)];
  })], 2)]), _vm.qrBookingInfo ? _c('div', {
    staticClass: "d-flex-center flex-column"
  }, [_c('div', {
    staticClass: "font-weight-bolder color-theme text-nowrap mb-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.qrBookingInfo', _vm.config.locale)) + " ")]), _c('QrcodeVue', {
    attrs: {
      "value": _vm.qrBookingInfo,
      "size": "100",
      "level": "M"
    }
  })], 1) : _vm._e()]), _c('BAlert', {
    staticClass: "px-2 py-1 mb-0",
    attrs: {
      "show": _vm.flights && !_vm.flights.length,
      "variant": "warning"
    }
  }, [_vm._v(" Không có hành trình trong mã đặt chỗ. ")]), _vm._l(_vm.flights, function (flight, index) {
    return _c('div', {
      key: index,
      staticClass: "section-flight d-flex flex-column text-gray bg-theme position-relative",
      class: [{
        'border-theme': !_vm.config.showBackgroundColor
      }]
    }, [_c('div', {
      staticClass: "d-flex gap-4px position-absolute",
      staticStyle: {
        "top": "8px",
        "right": "8px",
        "z-index": "10"
      }
    }, [_c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFlight(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "MinusIcon",
        "size": "16"
      }
    })], 1), _c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-success"
      },
      on: {
        "click": function click($event) {
          return _vm.addFlight(index, flight);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon",
        "size": "16"
      }
    })], 1)], 1), _vm._l(flight.segments, function (segment, sIndex) {
      return [_c('div', {
        key: "segment-wrapper-".concat(index, "-").concat(sIndex),
        staticClass: "segment-content"
      }, [_vm.agencyAvatar ? _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.config.showBackground,
          expression: "config.showBackground"
        }],
        style: "position: absolute; inset: 30px; opacity: 0.08; background: url(".concat(_vm.agencyAvatar, ") no-repeat center / contain")
      }) : _vm._e(), _c('div', {
        staticClass: "segment-content-l d-flex flex-column gap-16px text-black"
      }, [_c('div', {
        staticClass: "segment-booking-code border-theme p-0 d-flex-center align-self-stretch color-theme bg-white position-relative"
      }, [_c('svg', {
        staticClass: "d-flex-center",
        attrs: {
          "viewBox": "0 0 67 67",
          "width": "80",
          "fill": "none",
          "xmlns": "http://www.w3.org/2000/svg"
        }
      }, [_c('path', {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          "d": "M-2.18175 31.151L-35 -13.198L-19.727 -24.5L21.4547 31.151L-19.727 86.802L-35 75.5L-2.18175 31.151Z",
          "fill": "currentColor"
        }
      }), _c('path', {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          "d": "M20.2728 31.151L-12.5454 -13.198L2.72759 -24.5L43.9093 31.151L2.72759 86.802L-12.5454 75.5L20.2728 31.151Z",
          "fill": "currentColor",
          "fill-opacity": "0.6869"
        }
      }), _c('path', {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          "d": "M42.7279 31.151L9.90967 -13.198L25.1827 -24.5L66.3644 31.151L25.1827 86.802L9.90967 75.5L42.7279 31.151Z",
          "fill": "currentColor",
          "fill-opacity": "0.2"
        }
      })]), _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.config.showStatus,
          expression: "config.showStatus"
        }],
        staticClass: "payment-status position-absolute cursor-pointer",
        class: flight.bookingStatus ? flight.bookingStatus === 'PAID' ? 'bg-current' : flight.bookingStatus === 'HOLD' ? 'border-theme' : 'bg-danger border-danger' : _vm.config.status.toUpperCase() === 'PAID' ? 'bg-current' : 'border-theme',
        staticStyle: {
          "top": "24px"
        },
        attrs: {
          "id": "electronic-ticket-status"
        },
        on: {
          "click": function click($event) {
            return _vm.toggleConfigStatus(flight);
          }
        }
      }, [_c('span', {
        class: flight.bookingStatus ? flight.bookingStatus === 'PAID' ? 'text-white' : flight.bookingStatus === 'HOLD' ? 'color-theme' : ' text-white' : _vm.config.status.toUpperCase() === 'PAID' ? 'text-white' : _vm.config.status === 'EXPIRED' ? 'text-red' : 'color-theme'
      }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(flight.bookingStatus || _vm.config.status), _vm.config.locale)) + " ")])]), _c('b-tooltip', {
        staticClass: "cursor-pointer",
        attrs: {
          "target": "electronic-ticket-status",
          "triggers": "hover",
          "placement": "top",
          "boundary": "viewport",
          "variant": "danger"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.clickToChangeStatus')) + " "), _c('span', {
        staticClass: "fw-700"
      }, [_vm._v(" " + _vm._s(_vm.config.status.toUpperCase() === 'PAID' ? _vm.$t('ticket.statusHold', _vm.config.locale) : _vm.config.status.toUpperCase() === 'HOLD' ? _vm.$t('ticket.statusCancel', _vm.config.locale) : _vm.$t('ticket.statusPaid', _vm.config.locale)) + " ")])]), _c('div', {
        staticClass: "fw-700 text-center text-24px flex-grow-0 px-1",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.bookingID', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "fw-700 text-center text-30px text-uppercase text-nowrap flex-grow-1",
        staticStyle: {
          "padding-right": "36px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(flight.bookingCode) + " "), _vm.resolveAirPnr(flight.supplierPNRs, flight.airline) ? _c('span', [_vm._v("/" + _vm._s(_vm.resolveAirPnr(flight.supplierPNRs, flight.airline)))]) : _vm._e()])], 1), _c('div', {
        staticClass: "d-flex justify-content-between align-items-center fw-700 text-20px"
      }, [_c('div', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirportName(segment.from)) + " ")]), _c('div', {
        staticClass: "text-right",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirportName(segment.to)) + " ")])]), _c('div', {
        staticClass: "d-flex justify-content-between align-items-end fw-700"
      }, [_c('div', [_c('div', {
        staticClass: "d-flex gap-4px align-items-center"
      }, [_c('span', {
        staticClass: "text-16px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.departTime', _vm.config.locale)) + " ")]), _c('span', {
        staticClass: "text-30px px-12px color-theme bg-white rounded-8px border-theme",
        staticStyle: {
          "padding-top": "2px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.departTime) + " ")])]), _c('div', {
        staticClass: "text-20px mt-4px"
      }, [_c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.departWeekday) + ", " + _vm._s(segment.departDate) + " ")])])]), _c('IconAirplane'), _c('div', {
        staticClass: "text-right"
      }, [_c('div', {
        staticClass: "d-flex gap-4px align-items-center"
      }, [_c('span', {
        staticClass: "text-30px px-12px color-theme bg-white rounded-8px border-theme",
        staticStyle: {
          "padding-top": "2px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.arrivalTime) + " ")]), _c('span', {
        staticClass: "text-16px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.arrivalTime', _vm.config.locale)) + " ")])]), _c('div', {
        staticClass: "text-20px mt-4px"
      }, [_c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.arrivalWeekday) + ", " + _vm._s(segment.arrivalDate) + " ")])])])], 1), segment.startTerminal && segment.endTerminal ? _c('div', {
        staticClass: "d-flex justify-content-between align-items-center"
      }, [_c('div', {
        staticClass: "d-flex gap-8px align-items-center font-weight-bolder text-black"
      }, [_c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.terminal', _vm.config.locale)) + " " + _vm._s(segment.startTerminal.replace('TERMINAL', '')) + " ")]), _c('IconDeparture', {
        attrs: {
          "height": "18"
        }
      })], 1), _c('div', {
        staticClass: "d-flex gap-8px align-items-center font-weight-bolder"
      }, [_c('IconArrival', {
        attrs: {
          "height": "18"
        }
      }), _c('span', {
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.terminal', _vm.config.locale)) + " " + _vm._s(segment.endTerminal.replace('TERMINAL', '')) + " ")])], 1)]) : _vm._e()]), _vm._m(2, true), _c('div', {
        staticClass: "segment-content-r d-flex flex-column gap-8px fw-700"
      }, [_c('div', {
        staticClass: "airline d-flex-center gap-16px"
      }, [_vm.airlinesLogoLong.includes(segment.operating || segment.airline) ? _c('div', [_c('IAmLogoAirline', {
        attrs: {
          "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
          "long-logo": true,
          "hide-tooltip": true,
          "size": "lg",
          "blob": ""
        }
      })], 1) : _c('div', {
        staticClass: "d-flex-center gap-1"
      }, [_c('IAmLogoAirline', {
        attrs: {
          "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
          "hide-tooltip": true,
          "height": 50,
          "blob": ""
        }
      }), _c('div', {
        staticClass: "text-24px text-black font-weight-bolder"
      }, [_vm._v(" " + _vm._s(_vm.getAirlineNameByCode(segment.operating === 'BL' ? segment.operating : segment.airline)) + " ")])], 1)]), _vm.config.showBookingClass ? _c('div', {
        staticClass: "d-flex-center",
        staticStyle: {
          "position": "relative"
        }
      }, [_vm.getGroupClass(segment) ? _c('img', {
        staticClass: "rounded-8px w-100",
        staticStyle: {
          "max-width": "320px"
        },
        attrs: {
          "src": require("@/assets/images/ticket/img_ticket_".concat(_vm.getGroupClass(segment).toLowerCase(), "_class.png"))
        }
      }) : _c('img', {
        staticClass: "rounded-8px w-100",
        staticStyle: {
          "max-width": "320px"
        },
        attrs: {
          "src": require("@/assets/images/ticket/img_ticket_".concat('economy', "_class.png"))
        }
      })]) : _vm._e(), _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        staticStyle: {
          "margin-top": "10px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.flight', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-24px px-12px color-theme bg-white rounded-8px border-theme",
        staticStyle: {
          "padding-top": "2px"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.flight) + " ")])]), segment.airCraft ? _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.aircraft', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-black",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirCraftByIata(segment.airCraft)) + " ")])]) : _vm._e(), _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.duration', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-black",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.durationHM) + " ")])]), (segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).carryBag : _vm.getHandBaggage(segment)) ? _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.handBaggage', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-black",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).carryBag : _vm.getHandBaggage(segment)) + " ")])]) : _vm._e(), (segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).checkinBag : _vm.getFreeBaggage(segment)) ? _c('div', {
        staticClass: "d-flex justify-content-between align-items-center gap-4px"
      }, [_c('div', {
        staticClass: "text-14px",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.freeBaggage', _vm.config.locale)) + ": ")]), _c('div', {
        staticClass: "text-black",
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm._v(" " + _vm._s(segment.segmentNotModified ? _vm.getBaggageInBookingDetail(segment.segmentNotModified, _vm.ticketData, _vm.config.locale).checkinBag : _vm.getFreeBaggage(segment)) + " ")])]) : _vm._e()])]), segment.transitTime ? _c('div', {
        key: "transit-time".concat(index, "-").concat(sIndex),
        staticClass: "d-flex-center gap-12px px-2 color-theme my-12px"
      }, [_c('div', {
        staticClass: "border-dot flex-grow-1"
      }), _c('div', {
        staticClass: "flex-shrink-0 font-weight-bolder text-14px",
        attrs: {
          "contenteditable": ""
        }
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.transitIn', _vm.config.locale)) + " " + _vm._s(_vm.getAirportName(segment.to)) + " - " + _vm._s(_vm.$t('ticket.flightsInfo.transitTime', _vm.config.locale)) + ": " + _vm._s(segment.transitTime) + " ")]), _c('div', {
        staticClass: "border-dot flex-grow-1"
      })]) : _vm._e()];
    })], 2);
  }), _vm.ticketData.status !== 'CANCEL' ? _c('div', [_vm.config.showPrice && _vm.fareBookingDataArray.length ? _c('div', {
    staticClass: "d-flex flex-column gap-8px"
  }, [_vm.config.showPriceDetail ? _c('div', {
    staticClass: "d-flex-center flex-column gap-8px"
  }, [_c('div', {
    staticClass: "font-weight-bolder color-theme px-24px d-flex justify-content-start w-100",
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.ticketPrice', _vm.config.locale)) + " ")]), _vm._l(_vm.fareBookingDataArray, function (paxFareItem, paxFareIndex) {
    return _c('div', {
      key: paxFareIndex,
      staticClass: "w-100"
    }, [paxFareItem && paxFareItem.trip ? _c('div', {
      staticClass: "text-dark font-weight-bolder mb-50"
    }, [_c('span', {
      staticClass: "text-16px"
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(paxFareItem.trip) + " ")])])]) : _vm._e(), _c('div', {
      staticClass: "section-table",
      class: [{
        'border-theme': !_vm.config.showBackgroundColor
      }]
    }, [paxFareItem && paxFareItem.paxFare && paxFareItem.paxFare.length ? _c('b-table', {
      staticClass: "mb-0",
      attrs: {
        "centered": "",
        "responsive": "",
        "borderless": "",
        "thead-class": "text-dark align-middle text-nowrap text-right border-0",
        "tbody-class": "text-dark align-middle text-nowrap text-right border-0",
        "fields": _vm.tableColumnsPaxFareInfoTicket,
        "items": paxFareItem.paxFare
      },
      scopedSlots: _vm._u([_vm._l(_vm.tableColumnsPaxFareInfoTicket, function (column, indexColumn) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('span', {
              key: indexColumn,
              attrs: {
                "contenteditable": ""
              }
            }, [_vm._v(" " + _vm._s(_vm.$t("reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.".concat(data.column), _vm.config.locale)) + " ")])];
          }
        };
      }), {
        key: "cell(passenger)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', {
            attrs: {
              "contenteditable": ""
            }
          }, [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.item.paxType), _vm.config.locale)) + " x" + _vm._s(data.item.amountPassenger))])])];
        }
      }, {
        key: "cell(netPrice)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": Math.floor(data.item.netPrice / data.item.amountPassenger),
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.paxType, 'netPrice');
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(tax)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": Math.floor(data.item.tax / data.item.amountPassenger),
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.paxType, 'tax');
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(total)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder color-theme"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": Math.floor(data.item.total / data.item.amountPassenger),
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.paxType, 'total');
              }
            }
          })], 1)];
        }
      }], null, true)
    }) : paxFareItem && paxFareItem.fareInfoTicket ? _c('b-table', {
      staticClass: "mb-0",
      attrs: {
        "centered": "",
        "responsive": "",
        "borderless": "",
        "thead-class": "text-dark align-middle text-nowrap text-right border-0",
        "tbody-class": "text-dark align-middle text-nowrap text-right border-0",
        "fields": _vm.tableColumnsFareInfoTicket,
        "items": paxFareItem.fareInfoTicket
      },
      scopedSlots: _vm._u([_vm._l(_vm.tableColumnsFareInfoTicket, function (column, indexColumn) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('span', {
              key: indexColumn,
              attrs: {
                "contenteditable": ""
              }
            }, [_vm._v(" " + _vm._s(_vm.$t("reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.".concat(data.column), _vm.config.locale)) + " ")])];
          }
        };
      }), {
        key: "cell(passenger)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', {
            attrs: {
              "contenteditable": ""
            }
          }, [_vm._v(_vm._s(data.item.passenger))])])];
        }
      }, {
        key: "cell(netPrice)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": data.item.netPrice,
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.passenger, 'netPrice', true);
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(tax)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": data.item.tax,
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.passenger, 'tax', true);
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(total)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder color-theme"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": data.item.total,
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, paxFareIndex, data.item.passenger, 'total', true);
              }
            }
          })], 1)];
        }
      }], null, true)
    }) : _vm._e(), _vm.showTotalPerTrip ? _c('div', {
      staticClass: "text-right mx-1 my-50"
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceTable.total', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
      attrs: {
        "value-money": paxFareItem.total,
        "placeholder": "",
        "custom-class": "fw-700 color-theme",
        "disable-class": true,
        "can-input-negative": true,
        "on-blur": function onBlur(text) {
          return _vm.handleChangeBookingTotal(text, paxFareIndex);
        }
      }
    })], 1) : _vm._e()], 1)]);
  })], 2) : _vm._e(), _vm.config.showAncillaries && _vm.ancillaryTotal ? _c('div', {
    staticClass: "d-flex-center flex-column gap-8px mt-1"
  }, [_c('div', {
    staticClass: "font-weight-bolder color-theme px-24px d-flex justify-content-start w-100",
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.ticketAncillaries', _vm.config.locale)) + " ")]), _vm._l(_vm.ancillariesBookingDataArray, function (paxFareItem, paxFareIndex) {
    return _c('div', {
      key: paxFareIndex,
      staticClass: "w-100"
    }, [paxFareItem && paxFareItem.trip ? _c('div', {
      staticClass: "text-dark font-weight-bolder mb-50"
    }, [_c('span', {
      staticClass: "text-16px"
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(paxFareItem.trip) + " ")])])]) : _vm._e(), _c('div', {
      staticClass: "section-table",
      class: [{
        'border-theme': !_vm.config.showBackgroundColor
      }]
    }, [_c('AncillariesTable', {
      attrs: {
        "reservations-data": paxFareItem,
        "on-change": function onChange(text, itemId) {
          return _vm.handleChangeAncillaryItem(text, paxFareIndex, itemId);
        }
      }
    }), _vm.showTotalPerTrip ? _c('div', {
      staticClass: "text-right m-50"
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceTable.total', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
      attrs: {
        "value-money": paxFareItem.ancillaryTotal,
        "placeholder": "",
        "custom-class": "fw-700 color-theme",
        "disable-class": true,
        "can-input-negative": true,
        "on-blur": function onBlur(text) {
          return _vm.handleChangeAncillarySum(text, paxFareIndex);
        }
      }
    })], 1) : _vm._e()], 1)]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-column gap-2 p-1 bg-theme w-100",
    staticStyle: {
      "border-radius": "10px"
    }
  }, [_vm.config.showAncillaries && _vm.totalAncillaryPriceRaw ? _c('div', {
    staticClass: "d-flex align-items-end justify-content-end"
  }, [_c('span', {
    staticClass: "font-weight-bolder",
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.ticketAncillaries', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
    attrs: {
      "value-money": _vm.totalAncillaryPrice,
      "placeholder": "",
      "custom-class": "text-18px fw-700 color-theme",
      "disable-class": true,
      "can-input-negative": true,
      "on-blur": function onBlur(text) {
        return _vm.handleChangeAncillaryPrice(text);
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-end justify-content-end"
  }, [_c('span', {
    staticClass: "font-weight-bolder",
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceTable.totalOfPrice', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
    attrs: {
      "value-money": _vm.sumAll,
      "placeholder": "",
      "custom-class": "text-18px fw-700 color-theme",
      "disable-class": true,
      "can-input-negative": true,
      "on-blur": function onBlur(text) {
        return _vm.handleChangeSumAll(text);
      }
    }
  })], 1)])]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "section-note",
    class: [{
      'border-theme': !_vm.config.showBackgroundColor
    }]
  }, [_c('div', {
    staticStyle: {
      "flex": "1",
      "position": "relative"
    }
  }, [_vm.config.locale === 'vi' ? _c('div', [_c('quill-editor', {
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.config.note,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "note", $$v);
      },
      expression: "config.note"
    }
  })], 1) : _vm.config.locale === 'en' ? _c('div', [_c('quill-editor', {
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.config.noteEn,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "noteEn", $$v);
      },
      expression: "config.noteEn"
    }
  })], 1) : _vm._e(), _vm.isConfig && _vm.config.note ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger.window",
      modifiers: {
        "hover": true,
        "v-danger": true,
        "window": true
      }
    }],
    staticClass: "p-1 rounded-circle",
    staticStyle: {
      "position": "absolute",
      "right": "-20px",
      "top": "0"
    },
    attrs: {
      "title": "Đặt lại ghi chú về mặc định",
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.handleResetNote
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "RotateCwIcon",
      "size": "20"
    }
  })], 1) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.config.showPayment,
      expression: "config.showPayment"
    }],
    staticClass: "d-flex-center flex-column gap-4px",
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_c('div', {
    staticClass: "font-weight-bolder color-theme text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paymentInfo', _vm.config.locale)) + " ")]), _c('div', {
    staticClass: "border"
  }, [_c('b-img', {
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "src": _vm.qrCodeAvatar
    }
  })], 1), _vm.defaultBankAccount ? _c('div', {
    staticClass: "font-weight-bolder",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.defaultBankAccount.bankAccountName))]), _c('div', [_vm._v(_vm._s(_vm.defaultBankAccount.bankAccountNo))]), _c('div', {
    staticClass: "d-flex-center justify-content-start"
  }, [_c('BImg', {
    attrs: {
      "src": _vm.bankAvatar,
      "width": "70"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.resolveBankCode(_vm.defaultBankAccount.bankCode).bankName))])], 1)]) : _vm._e()])])], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.config.agencyInfo)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.config.agencyInfoEn)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "barcode-wrapper"
  }, [_c('img', {
    attrs: {
      "src": "/ticket/barcode26x240.png",
      "alt": "barcode"
    }
  })]);
}]

export { render, staticRenderFns }