var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.25 14.2839C11.7018 14.2839 14.5 11.4705 14.5 8.00011C14.5 4.52967 11.7018 1.71632 8.25 1.71632C4.79822 1.71632 2 4.52967 2 8.00011C2 11.4705 4.79822 14.2839 8.25 14.2839Z",
      "fill": "#E4E7EC",
      "stroke": "black",
      "stroke-width": "3"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }