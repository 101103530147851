<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="e-ticket d-flex flex-column bg-white mx-auto"
    :class="[
      printSize,
      `theme-${currentTheme}`,
      { 'border border-theme': frontendI18n },
      { 'showBackgroundColor': config.showBackgroundColor}
    ]"
  >
    <!-- agency info -->
    <div
      v-show="config.showAgency"
      class="section-agency"
    >
      <div style="flex: 1; position: relative;">
        <div v-if="config.locale === 'vi'">
          <quill-editor
            v-if="config.useQuillEditor"
            v-model="config.agencyInfo"
            :options="editorOption"
          />
          <div
            v-else-if="config.agencyInfo"
            contenteditable="true"
            @input="config.agencyInfo = $event.target.innerHTML"
          >
            <div
              v-once
              v-html="config.agencyInfo"
            />
          </div>
          <div
            v-else
            contenteditable="true"
            @input="config.agencyInfo = $event.target.innerHTML"
          >
            <div v-if="agencyInfo">
              <div>
                <b>{{ agencyInfo.agencyName }}</b>
              </div>
              <div>
                Địa chỉ: {{ agencyInfo.agencyAddress }}
              </div>
              <div>
                Hotline: {{ agencyInfo.ownerPhoneNumber }}
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="config.locale === 'en'">
          <quill-editor
            v-if="config.useQuillEditor"
            v-model="config.agencyInfoEn"
            :options="editorOption"
          />
          <div
            v-else-if="config.agencyInfoEn"
            contenteditable="true"
            @input="config.agencyInfoEn = $event.target.innerHTML"
          >
            <div
              v-once
              v-html="config.agencyInfoEn"
            />
          </div>
          <div
            v-else
            contenteditable="true"
            @input="config.agencyInfoEn = $event.target.innerHTML"
          >
            <div v-if="agencyInfoEn">
              <div>
                <b>{{ agencyInfoEn.agencyName }}</b>
              </div>
              <div>
                Address: {{ agencyInfoEn.agencyAddress }}
              </div>
              <div>
                Hotline: {{ agencyInfoEn.ownerPhoneNumber }}
              </div>
            </div>
          </div>
        </div>

        <b-button
          v-if="isConfig && (config.agencyInfo || config.agencyInfoEn)"
          v-b-tooltip.hover.v-danger.window
          style="position: absolute; right: 0; top: 0;"
          title="Đặt lại thông tin đại lý về mặc định"
          variant="flat-danger"
          class="p-1 rounded-circle"
          @click="handleResetAgencyInfo"
        >
          <feather-icon
            icon="RotateCwIcon"
            size="20"
          />
        </b-button>
      </div>
      <b-img
        v-if="agencyAvatar && config.showAgencyLogo"
        :src="agencyAvatar"
        style="height: 80px;"
      />
    </div>

    <!-- title & time hold -->
    <div class="d-flex-center justify-content-between text-20px fw-700 px-20px">
      <div
        class="color-theme text-uppercase"
        contenteditable="true"
      >
        {{ $t('ticket.eTicket', config.locale) }}
      </div>
      <div
        v-show="config.showTimeHold"
        class="text-red text-14px font-weight-bolder"
      >
        <template v-if="frontendI18n">
          {{ $t('ticket.prepaymentNote', config.locale) }}
        </template>
        <template v-else-if="config.status.toUpperCase() !== 'PAID'">
          <div
            contenteditable="true"
            @input="config.timeHoldNote = $event.target.textContent.trim()"
          >
            <span v-if="timeHold">
              {{ $t('ticket.payBefore', config.locale) }}: {{ timeHold }}
            </span>
          </div>
        </template>
      </div>
    </div>

    <!-- SECTION - passengers -->
    <div class="d-flex-center gap-2">
      <div class="flex-1">
        <div
          class="section-table pax-table-template-columns"
          :class="[
            { 'border-theme': !config.showBackgroundColor}
          ]"
        >
          <div
            class="bg-theme fw-700 d-flex align-items-center p-4px-16px"
            contenteditable="true"
          >
            {{ $t('ticket.paxTable.no', config.locale) }}
          </div>

          <div
            class="bg-theme fw-700 d-flex align-items-center p-4px-16px"
            contenteditable="true"
          >
            {{ $t('ticket.paxTable.passenger', config.locale) }}
          </div>

          <div
            class="bg-theme fw-700 d-flex align-items-center p-4px-16px"
            contenteditable="true"
          >
            {{ $t('ticket.paxTable.additionalServices', config.locale) }}
          </div>

          <template v-for="(item, index) in newPaxs">
            <div
              :key="`no-${index}`"
              class="p-4px-16px d-flex align-items-center w-100 border-t"
            >
              <div contenteditable="true">
                {{ String(index + 1).padStart(2, "0") }}
              </div>
            </div>
            <div
              :key="`pax-${index}`"
              class="p-4px-16px d-flex align-items-center w-100 border-t"
            >
              <div
                contenteditable="true"
                style="width: 100%; font-weight: 700;"
              >
                <template v-if="!frontendI18n && item?.passenger?.paxType && item?.passenger?.title">
                  ({{ item.passenger.title && $te(`reservation.titleOptions.${item.passenger.paxType}.${item.passenger.title}`, config.locale)
                    ? $t(`reservation.titleOptions.${item.passenger.paxType}.${item.passenger.title}`, config.locale)
                    : item.passenger.title }})
                </template>
                <span class="fw-700">
                  {{
                    frontendI18n && !isEmpty(item.passenger)
                      ? $t(item.passenger)
                      : getLocaleFullName(item.passenger)
                  }}
                </span>
              </div>
            </div>

            <div
              :key="`bag-${index}`"
              class="p-4px-16px d-flex align-items-center w-100 gap-1 border-t"
            >
              <div
                contenteditable="true"
                class="flex-1"
              >
                <template v-if="frontendI18n">
                  {{ $t(item.bags, config.locale) }}
                </template>

                <template v-else-if="item.bags && item.bags.length">
                  <template v-for="(bag, bagIndex) in item.bags">
                    <div
                      v-if="bag.xBagName && bag.trip"
                      :key="`BAG-${bagIndex}-${bag.segmentIds[0]}-${bag.paxId}-${bag.id}`"
                    >
                      {{ bag.trip }}:
                      {{ bag.xBagName }}
                    </div>
                  </template>
                </template>

                <template v-if="item.others && item.others.length">
                  <template v-for="(other, otherIndex) in item.others">
                    <div
                      v-if="other.trip && other.otherName"
                      :key="`OTHER-${otherIndex}-${other.segmentIds[0]}-${other.paxId}-${other.serviceSubCode}-${other.id}`"
                    >
                      {{ other.trip }}:
                      {{ other.otherName }}
                    </div>
                  </template>
                </template>

                <template v-if="item.seat && item.seat.length">
                  <template v-for="(seat, seatIndex) in item.seat">
                    <div
                      v-if="seat.seatName"
                      :key="`seat-${seatIndex}-${seat.segmentIds[0]}-${seat.paxId}-${seat.id}-${seat.pdcSeat}`"
                    >
                      {{ seat.trip }}:
                      {{ seat.seatName }}
                    </div>
                  </template>
                </template>
              </div>
              <b-button
                class="pax-button"
                variant="outline-danger"
                style="padding: 4px;"
                @click="removePax(index)"
              >
                <feather-icon
                  icon="MinusIcon"
                  size="16"
                />
              </b-button>
              <b-button
                class="pax-button"
                variant="outline-success"
                style="padding: 4px;"
                @click="addPax(index)"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
              </b-button>
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="qrBookingInfo"
        class="d-flex-center flex-column"
      >
        <div class="font-weight-bolder color-theme text-nowrap mb-50">
          {{ $t('ticket.qrBookingInfo', config.locale) }}
        </div>
        <QrcodeVue
          :value="qrBookingInfo"
          size="100"
          level="M"
        />
      </div>
    </div>
    <!-- !SECTION - passengers -->

    <!-- ticket -->
    <BAlert
      :show="flights && !flights.length"
      variant="warning"
      class="px-2 py-1 mb-0"
    >
      Không có hành trình trong mã đặt chỗ.
    </BAlert>
    <div
      v-for="(flight, index) in flights"
      :key="index"
      class="section-flight d-flex flex-column text-gray bg-theme position-relative"
      :class="[
        { 'border-theme': !config.showBackgroundColor}
      ]"
    >
      <div
        class="d-flex gap-4px position-absolute"
        style="top: 8px; right: 8px; z-index: 10;"
      >
        <b-button
          class="pax-button"
          variant="outline-danger"
          style="padding: 4px;"
          @click="removeFlight(index)"
        >
          <feather-icon
            icon="MinusIcon"
            size="16"
          />
        </b-button>
        <b-button
          class="pax-button"
          variant="outline-success"
          style="padding: 4px;"
          @click="addFlight(index, flight)"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
        </b-button>
      </div>

      <template v-for="(segment, sIndex) in flight.segments">
        <div
          :key="`segment-wrapper-${index}-${sIndex}`"
          class="segment-content"
        >
          <div
            v-if="agencyAvatar"
            v-show="config.showBackground"
            :style="`position: absolute; inset: 30px; opacity: 0.08; background: url(${agencyAvatar}) no-repeat center / contain`"
          />
          <div class="segment-content-l d-flex flex-column gap-16px text-black">
            <div class="segment-booking-code border-theme p-0 d-flex-center align-self-stretch color-theme bg-white position-relative">
              <svg
                class="d-flex-center"
                viewBox="0 0 67 67"
                width="80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <!-- Màu đậm nhất: currentColor as css class .color-theme -->
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M-2.18175 31.151L-35 -13.198L-19.727 -24.5L21.4547 31.151L-19.727 86.802L-35 75.5L-2.18175 31.151Z"
                  fill="currentColor"
                />
                <!-- Màu trung bình: currentColor với opacity ~69% -->
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.2728 31.151L-12.5454 -13.198L2.72759 -24.5L43.9093 31.151L2.72759 86.802L-12.5454 75.5L20.2728 31.151Z"
                  fill="currentColor"
                  fill-opacity="0.6869"
                />
                <!-- Màu nhạt: currentColor với opacity 20% -->
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M42.7279 31.151L9.90967 -13.198L25.1827 -24.5L66.3644 31.151L25.1827 86.802L9.90967 75.5L42.7279 31.151Z"
                  fill="currentColor"
                  fill-opacity="0.2"
                />
              </svg>

              <div
                v-show="config.showStatus"
                id="electronic-ticket-status"
                class="payment-status position-absolute cursor-pointer"
                :class="flight.bookingStatus
                  ? (flight.bookingStatus === 'PAID'
                    ? 'bg-current' : (flight.bookingStatus === 'HOLD'
                      ? 'border-theme' : 'bg-danger border-danger') )
                  : config.status.toUpperCase() === 'PAID' ? 'bg-current' : 'border-theme'"
                style="top: 24px"
                @click="toggleConfigStatus(flight)"
              >
                <span
                  :class="flight.bookingStatus
                    ? (flight.bookingStatus === 'PAID'
                      ? 'text-white' : (flight.bookingStatus === 'HOLD'
                        ? 'color-theme' : ' text-white') )
                    : config.status.toUpperCase() === 'PAID'
                      ? 'text-white'
                      : (config.status === 'EXPIRED' ? 'text-red' : 'color-theme')"
                >
                  {{ $t(`reservation.${flight.bookingStatus || config.status}`, config.locale) }}
                </span>
              </div>
              <b-tooltip
                class="cursor-pointer"
                target="electronic-ticket-status"
                triggers="hover"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                {{ $t('ticket.clickToChangeStatus') }}
                <span class="fw-700">
                  {{
                    config.status.toUpperCase() === 'PAID'
                      ? $t('ticket.statusHold', config.locale)
                      : config.status.toUpperCase() === 'HOLD'
                        ? $t('ticket.statusCancel', config.locale)
                        : $t('ticket.statusPaid', config.locale)
                  }}
                </span>
              </b-tooltip>

              <div
                class="fw-700 text-center text-24px flex-grow-0 px-1"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.bookingID', config.locale) }}:
              </div>
              <div
                class="fw-700 text-center text-30px text-uppercase text-nowrap flex-grow-1"
                style="padding-right: 36px"
                contenteditable="true"
              >
                {{ flight.bookingCode }}
                <span v-if="resolveAirPnr(flight.supplierPNRs, flight.airline)">/{{ resolveAirPnr(flight.supplierPNRs, flight.airline) }}</span>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center fw-700 text-20px">
              <div contenteditable="true">
                {{ getAirportName(segment.from) }}
              </div>
              <div
                contenteditable="true"
                class="text-right"
              >
                {{ getAirportName(segment.to) }}
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-end fw-700">
              <div>
                <div class="d-flex gap-4px align-items-center">
                  <span
                    class="text-16px"
                    contenteditable="true"
                  >
                    {{ $t('ticket.flightsInfo.departTime', config.locale) }}
                  </span>
                  <span
                    class="text-30px px-12px color-theme bg-white rounded-8px border-theme"
                    style="padding-top: 2px;"
                    contenteditable="true"
                  >
                    {{ segment.departTime }}
                  </span>
                </div>
                <div class="text-20px mt-4px">
                  <span contenteditable="true">
                    {{ segment.departWeekday }}, {{ segment.departDate }}
                  </span>
                </div>
              </div>
              <IconAirplane />
              <div class="text-right">
                <div class="d-flex gap-4px align-items-center">
                  <span
                    class="text-30px px-12px color-theme bg-white rounded-8px border-theme"
                    style="padding-top: 2px;"
                    contenteditable="true"
                  >
                    {{ segment.arrivalTime }}
                  </span>
                  <span
                    class="text-16px"
                    contenteditable="true"
                  >
                    {{ $t('ticket.flightsInfo.arrivalTime', config.locale) }}
                  </span>
                </div>
                <div class="text-20px mt-4px">
                  <span contenteditable="true">
                    {{ segment.arrivalWeekday }}, {{ segment.arrivalDate }}
                  </span>
                </div>
              </div>
            </div>

            <div
              v-if="segment.startTerminal && segment.endTerminal"
              class="d-flex justify-content-between align-items-center"
            >
              <div class="d-flex gap-8px align-items-center font-weight-bolder text-black">
                <span contenteditable="true">
                  {{ $t('ticket.flightsInfo.terminal', config.locale) }} {{ segment.startTerminal.replace('TERMINAL', '') }}
                </span>
                <IconDeparture height="18" />
              </div>
              <div class="d-flex gap-8px align-items-center font-weight-bolder">
                <IconArrival height="18" />
                <span contenteditable="true">
                  {{ $t('ticket.flightsInfo.terminal', config.locale) }} {{ segment.endTerminal.replace('TERMINAL', '') }}
                </span>
              </div>
            </div>
          </div>

          <div class="barcode-wrapper">
            <img
              src="/ticket/barcode26x240.png"
              alt="barcode"
            >
          </div>

          <div class="segment-content-r d-flex flex-column gap-8px fw-700">
            <div class="airline d-flex-center gap-16px">
              <div v-if="airlinesLogoLong.includes(segment.operating || segment.airline)">
                <IAmLogoAirline
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  :long-logo="true"
                  :hide-tooltip="true"
                  size="lg"
                  blob
                />
              </div>
              <div
                v-else
                class="d-flex-center gap-1"
              >
                <IAmLogoAirline
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  :hide-tooltip="true"
                  :height="50"
                  blob
                />
                <div class="text-24px text-black font-weight-bolder">
                  {{ getAirlineNameByCode(segment.operating === 'BL' ? segment.operating : segment.airline) }}
                </div>
              </div>
            </div>

            <!-- ANCHOR booking class -->
            <div
              v-if="config.showBookingClass"
              style="position: relative;"
              class="d-flex-center"
            >
              <img
                v-if="getGroupClass(segment)"
                :src="require(`@/assets/images/ticket/img_ticket_${getGroupClass(segment).toLowerCase()}_class.png`)"
                class="rounded-8px w-100"
                style="max-width: 320px;"
              >
              <img
                v-else
                :src="require(`@/assets/images/ticket/img_ticket_${'economy'}_class.png`)"
                class="rounded-8px w-100"
                style="max-width: 320px;"
              >
              <!-- <div
                style="position: absolute; inset: 0px;"
                class="group-class d-flex justify-content-center align-items-center"
                contenteditable="true"
              >
                {{ getGroupClass(segment) }}
              </div> -->
            </div>

            <!-- flight -->
            <div class="d-flex justify-content-between align-items-center gap-4px">
              <div
                class="text-14px"
                contenteditable="true"
                style="margin-top: 10px;"
              >
                {{ $t('ticket.flightsInfo.flight', config.locale) }}:
              </div>
              <div
                class="text-24px px-12px color-theme bg-white rounded-8px border-theme"
                style="padding-top: 2px"
                contenteditable="true"
              >
                {{ segment.flight }}
              </div>
            </div>

            <!-- aircraft -->
            <div
              v-if="segment.airCraft"
              class="d-flex justify-content-between align-items-center gap-4px"
            >
              <div
                class="text-14px"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.aircraft', config.locale) }}:
              </div>
              <div
                class="text-black"
                contenteditable="true"
              >
                {{ getAirCraftByIata(segment.airCraft) }}
              </div>
            </div>

            <!-- duration -->
            <div class="d-flex justify-content-between align-items-center gap-4px">
              <div
                class="text-14px"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.duration', config.locale) }}:
              </div>
              <div
                class="text-black"
                contenteditable="true"
              >
                {{ segment.durationHM }}
              </div>
            </div>

            <!-- boarding baggage -->
            <div
              v-if="segment.segmentNotModified
                ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).carryBag
                : getHandBaggage(segment)"
              class="d-flex justify-content-between align-items-center gap-4px"
            >
              <div
                class="text-14px"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.handBaggage', config.locale) }}:
              </div>
              <div
                class="text-black"
                contenteditable="true"
              >
                <!-- TODO: kiện -->
                {{ segment.segmentNotModified
                  ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).carryBag
                  : getHandBaggage(segment) }}
              </div>
            </div>

            <!-- checkin baggage -->
            <div
              v-if="segment.segmentNotModified
                ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).checkinBag
                : getFreeBaggage(segment)"
              class="d-flex justify-content-between align-items-center gap-4px"
            >
              <div
                class="text-14px"
                contenteditable="true"
              >
                {{ $t('ticket.flightsInfo.freeBaggage', config.locale) }}:
              </div>
              <div
                class="text-black"
                contenteditable="true"
              >
                <!-- TODO: kiện -->
                {{ segment.segmentNotModified
                  ? getBaggageInBookingDetail(segment.segmentNotModified, ticketData, config.locale).checkinBag
                  : getFreeBaggage(segment) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="segment.transitTime"
          :key="`transit-time${index}-${sIndex}`"
          class="d-flex-center gap-12px px-2 color-theme my-12px"
        >
          <div class="border-dot flex-grow-1" />
          <div
            class="flex-shrink-0 font-weight-bolder text-14px"
            contenteditable
          >
            {{ $t('ticket.flightsInfo.transitIn', config.locale) }}
            {{ getAirportName(segment.to) }}
            -
            {{ $t('ticket.flightsInfo.transitTime', config.locale) }}:
            {{ segment.transitTime }}
          </div>
          <div class="border-dot flex-grow-1" />
        </div>
      </template>
    </div>

    <!-- SECTION Bảng giá vé chi tiết -->
    <div v-if="ticketData.status !== 'CANCEL'">
      <div
        v-if="config.showPrice && fareBookingDataArray.length"
        class="d-flex flex-column gap-8px"
      >
        <div
          v-if="config.showPriceDetail"
          class="d-flex-center flex-column gap-8px"
        >
          <div
            class="font-weight-bolder color-theme px-24px d-flex justify-content-start w-100"
            contenteditable="true"
          >
            {{ $t('ticket.ticketPrice', config.locale) }}
          </div>

          <!-- SECTION GIÁ TỪNG PAX -->
          <div
            v-for="(paxFareItem, paxFareIndex) in fareBookingDataArray"
            :key="paxFareIndex"
            class="w-100"
          >
            <div
              v-if="paxFareItem && paxFareItem.trip"
              class="text-dark font-weight-bolder mb-50"
            >
              <span class="text-16px">
                <span contenteditable>
                  {{ paxFareItem.trip }}
                </span>
              </span>
            </div>

            <div
              class="section-table"
              :class="[{ 'border-theme': !config.showBackgroundColor}]"
            >
              <b-table
                v-if="paxFareItem && paxFareItem.paxFare && paxFareItem.paxFare.length"
                centered
                responsive
                borderless
                class="mb-0"
                thead-class="text-dark align-middle text-nowrap text-right border-0"
                tbody-class="text-dark align-middle text-nowrap text-right border-0"
                :fields="tableColumnsPaxFareInfoTicket"
                :items="paxFareItem.paxFare"
              >
                <template
                  v-for="(column, indexColumn) in tableColumnsPaxFareInfoTicket"
                  #[`head(${column})`]="data"
                >
                  <span
                    :key="indexColumn"
                    contenteditable
                  >
                    {{ $t(`reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.${data.column}`, config.locale) }}
                  </span>
                </template>
                <template #cell(passenger)="data">
                  <div class="text-right font-weight-bolder">
                    <span contenteditable>{{ $t(`reservation.${data.item.paxType}`, config.locale) }} x{{ data.item.amountPassenger }}</span>
                  </div>
                </template>
                <template #cell(netPrice)="data">
                  <div class="text-right font-weight-bolder">
                    <IAmInputMoney
                      :value-money="Math.floor( data.item.netPrice / data.item.amountPassenger)"
                      placeholder=""
                      :disable-class="true"
                      :can-input-negative="true"
                      :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.paxType, 'netPrice')"
                    />
                  </div>
                </template>
                <template #cell(tax)="data">
                  <div class="text-right font-weight-bolder">
                    <IAmInputMoney
                      :value-money="Math.floor(data.item.tax / data.item.amountPassenger)"
                      placeholder=""
                      :disable-class="true"
                      :can-input-negative="true"
                      :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.paxType, 'tax')"
                    />
                  </div>
                </template>
                <template #cell(total)="data">
                  <div class="text-right font-weight-bolder color-theme">
                    <IAmInputMoney
                      :value-money="Math.floor(data.item.total / data.item.amountPassenger)"
                      placeholder=""
                      :disable-class="true"
                      :can-input-negative="true"
                      :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.paxType, 'total')"
                    />
                  </div>
                </template>
              </b-table>

              <b-table
                v-else-if="paxFareItem && paxFareItem.fareInfoTicket"
                centered
                responsive
                borderless
                class="mb-0"
                thead-class="text-dark align-middle text-nowrap text-right border-0"
                tbody-class="text-dark align-middle text-nowrap text-right border-0"
                :fields="tableColumnsFareInfoTicket"
                :items="paxFareItem.fareInfoTicket"
              >
                <template
                  v-for="(column, indexColumn) in tableColumnsFareInfoTicket"
                  #[`head(${column})`]="data"
                >
                  <span
                    :key="indexColumn"
                    contenteditable
                  >
                    {{ $t(`reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.${data.column}`, config.locale) }}
                  </span>
                </template>
                <template #cell(passenger)="data">
                  <div class="text-right font-weight-bolder">
                    <span contenteditable>{{ data.item.passenger }}</span>
                  </div>
                </template>
                <template #cell(netPrice)="data">
                  <div class="text-right font-weight-bolder">
                    <IAmInputMoney
                      :value-money="data.item.netPrice"
                      placeholder=""
                      :disable-class="true"
                      :can-input-negative="true"
                      :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.passenger, 'netPrice', true)"
                    />
                  </div>
                </template>
                <template #cell(tax)="data">
                  <div class="text-right font-weight-bolder">
                    <IAmInputMoney
                      :value-money="data.item.tax"
                      placeholder=""
                      :disable-class="true"
                      :can-input-negative="true"
                      :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.passenger, 'tax', true)"
                    />
                  </div>
                </template>
                <template #cell(total)="data">
                  <div class="text-right font-weight-bolder color-theme">
                    <IAmInputMoney
                      :value-money="data.item.total"
                      placeholder=""
                      :disable-class="true"
                      :can-input-negative="true"
                      :on-blur="(text) => handleChangePax(text, paxFareIndex, data.item.passenger, 'total', true)"
                    />
                  </div>
                </template>
              </b-table>

              <!-- Tổng 1 bảng -->
              <div
                v-if="showTotalPerTrip"
                class="text-right mx-1 my-50"
              >
                <span contenteditable>
                  {{ $t('ticket.priceTable.total', config.locale) }}:
                </span>
                <IAmInputMoney
                  :value-money="paxFareItem.total"
                  placeholder=""
                  custom-class="fw-700 color-theme"
                  :disable-class="true"
                  :can-input-negative="true"
                  :on-blur="(text) => handleChangeBookingTotal(text, paxFareIndex)"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="config.showAncillaries && ancillaryTotal"
          class="d-flex-center flex-column gap-8px mt-1"
        >
          <div
            class="font-weight-bolder color-theme px-24px d-flex justify-content-start w-100"
            contenteditable="true"
          >
            {{ $t('ticket.ticketAncillaries', config.locale) }}
          </div>

          <!-- SECTION DỊCH VỤ BỔ SUNG -->
          <div
            v-for="(paxFareItem, paxFareIndex) in ancillariesBookingDataArray"
            :key="paxFareIndex"
            class="w-100"
          >
            <div
              v-if="paxFareItem && paxFareItem.trip"
              class="text-dark font-weight-bolder mb-50"
            >
              <span class="text-16px">
                <span contenteditable>
                  {{ paxFareItem.trip }}
                </span>
              </span>
            </div>

            <div
              class="section-table"
              :class="[
                { 'border-theme': !config.showBackgroundColor}
              ]"
            >
              <AncillariesTable
                :reservations-data="paxFareItem"
                :on-change="(text, itemId) => handleChangeAncillaryItem(text, paxFareIndex, itemId)"
              />

              <!-- Tổng 1 bảng -->
              <div
                v-if="showTotalPerTrip"
                class="text-right m-50"
              >
                <span contenteditable>
                  {{ $t('ticket.priceTable.total', config.locale) }}:
                </span>
                <IAmInputMoney
                  :value-money="paxFareItem.ancillaryTotal"
                  placeholder=""
                  custom-class="fw-700 color-theme"
                  :disable-class="true"
                  :can-input-negative="true"
                  :on-blur="(text) => handleChangeAncillarySum(text, paxFareIndex)"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="d-flex flex-column gap-2 p-1 bg-theme w-100"
          style="border-radius: 10px;"
        >
          <!-- ANCHOR Dịch vụ bổ sung -->
          <div
            v-if="config.showAncillaries && totalAncillaryPriceRaw"
            class="d-flex align-items-end justify-content-end"
          >
            <span
              contenteditable
              class="font-weight-bolder"
            >
              {{ $t('ticket.ticketAncillaries', config.locale) }}:
            </span>
            <IAmInputMoney
              :value-money="totalAncillaryPrice"
              placeholder=""
              custom-class="text-18px fw-700 color-theme"
              :disable-class="true"
              :can-input-negative="true"
              :on-blur="(text) => handleChangeAncillaryPrice(text)"
            />
          </div>
          <!-- ANCHOR Tổng cuối cùng -->
          <div class="d-flex align-items-end justify-content-end">
            <span
              contenteditable
              class="font-weight-bolder"
            >
              {{ $t('ticket.priceTable.totalOfPrice', config.locale) }}:
            </span>
            <IAmInputMoney
              :value-money="sumAll"
              placeholder=""
              custom-class="text-18px fw-700 color-theme"
              :disable-class="true"
              :can-input-negative="true"
              :on-blur="(text) => handleChangeSumAll(text)"
            />
          </div>
        </div>
        <!-- !SECTION -->
      </div>
    </div>
    <!-- !SECTION -->

    <!-- ANCHOR ticket note -->
    <div
      class="section-note"
      :class="[
        { 'border-theme': !config.showBackgroundColor}
      ]"
    >
      <div style="flex: 1; position: relative;">
        <div v-if="config.locale === 'vi'">
          <quill-editor
            v-model="config.note"
            :options="editorOption"
          />
        </div>
        <div v-else-if="config.locale === 'en'">
          <quill-editor
            v-model="config.noteEn"
            :options="editorOption"
          />
        </div>
        <b-button
          v-if="isConfig && config.note"
          v-b-tooltip.hover.v-danger.window
          style="position: absolute; right: -20px; top: 0;"
          title="Đặt lại ghi chú về mặc định"
          variant="flat-danger"
          class="p-1 rounded-circle"
          @click="handleResetNote"
        >
          <feather-icon
            icon="RotateCwIcon"
            size="20"
          />
        </b-button>
      </div>

      <div
        v-show="config.showPayment"
        class="d-flex-center flex-column gap-4px"
        style="margin-left: 20px;"
      >
        <div class="font-weight-bolder color-theme text-nowrap">
          {{ $t('ticket.paymentInfo', config.locale) }}
        </div>
        <div class="border">
          <b-img
            :src="qrCodeAvatar"
            style="width: 100px;"
          />
        </div>
        <div
          v-if="defaultBankAccount"
          style="text-align: center;"
          class="font-weight-bolder"
        >
          <div>{{ defaultBankAccount.bankAccountName }}</div>
          <div>{{ defaultBankAccount.bankAccountNo }}</div>
          <div class="d-flex-center justify-content-start">
            <BImg
              :src="bankAvatar"
              width="70"
            />
            <div>{{ resolveBankCode(defaultBankAccount.bankCode).bankName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
  watch,
  toRefs,
} from '@vue/composition-api'
import {
  BAlert,
  BImg,
  BTooltip, BButton, VBTooltip, BTable,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
import groupBy from 'lodash/groupBy'

import IAmInputMoney from '@/components/IAmInputMoney.vue'
import { resolveBookingStatusVariant, iataCodeIndia } from '@/constants/selectOptions'
import { THEMES, FARE_BOOKING_DATA_ARRAY_SAMPLE } from '@/constants/ticket'
import store from '@/store'
import { getLocaleFullName } from '@/views/apps/invoices/useInvoice'
import { toDataUrl } from '@/@core/utils/utils'
import useBankAccounts from '@/views/agency-setting/useBankAccounts'
import { airlinesLogoLong } from '@/constants/reservation'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'

import reservationStoreModule from '@reservation/reservationStoreModule'
import useReservationHandle, { resolveAirPnr } from '@reservation/useReservationHandle'

import useToast from '@useToast'

import AncillariesTable from './AncillariesTable.vue'
import IconAirplane from './icon/IconAirplane.vue'
import IconArrival from './icon/IconArrival.vue'
import IconDeparture from './icon/IconDeparture.vue'
import {
  getBaggagesByBookingClass,
  getGroupClass,
  getFreeBaggage,
  getHandBaggage,
} from './useSegmentBaggage'

export default {
  name: 'ElectronicTicketTemplate',

  components: {
    IAmInputMoney,
    BButton,
    BImg,
    BAlert,
    BTooltip,
    IconAirplane,
    IconArrival,
    IconDeparture,
    quillEditor,
    BTable,
    AncillariesTable,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    QrcodeVue: () => import('qrcode.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    frontendI18n: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    timeHold: {
      type: String,
      default: '',
    },
    fromFlights: {
      type: Array,
      default: () => [],
    },
    paxs: {
      type: Array,
      default: () => [],
    },
    isConfig: {
      type: Boolean,
      default: false,
    },
    fromTicketData: {
      type: Object,
      default: () => ({
        pricingInfo: {
          netFare: 1000000,
          tax: 100000,
          total: 1100000,
          currency: 'VND',
        },
        totalFeeService: 50000,
        bookingCode: 'NYQWRN',
        status: 'HOLD',
      }),
    },
    combinate: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit, root }) {
    const printSize = ref('a4')
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule)
    }
    const combinateRef = toRefs(props).combinate
    const { getBaggageInBookingDetail } = useReservationHandle()

    const ticketData = ref(props.fromTicketData)

    watch(() => props.fromTicketData, val => {
      ticketData.value = val
    }, { immediate: true })

    const flights = ref(props.fromFlights)

    // fetch unknown airport // thừa
    // watch(() => props.fromFlights, val => {
    //   flights.value = val
    //   const arrayAirport = []
    //   val.forEach(itinerary => {
    //     itinerary.segments.forEach(segment => {
    //       if (!arrayAirport.includes(segment.from)) {
    //         arrayAirport.push(segment.from)
    //       }
    //       if (!arrayAirport.includes(segment.to)) {
    //         arrayAirport.push(segment.to)
    //       }
    //     })
    //   })
    //   store.dispatch('app-reservation/fetchAirportsByCodes', arrayAirport)
    // }, { immediate: true })

    const removeFlight = index => {
      if (flights.value.length >= 1) { flights.value.splice(index, 1) }
    }

    const addFlight = (index, flight) => {
      flights.value = [...flights.value.slice(0, index + 1), flight, ...flights.value.slice(index + 1)]
    }

    function getAirportByAirportCode(airportCode) {
      if (!airportCode) return null
      return store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getAirportByAirportCode`](airportCode)
    }

    function getAirportName(airportCode) {
      if (!airportCode) return null
      const airportObj = getAirportByAirportCode(airportCode)
      if (!airportObj) return airportCode

      let airportName
      if (airportObj.group === 'VIỆT NAM' || airportObj.group === 'VIETNAM') {
        airportName = `${this.$t(`vnAirports.${airportCode}`)} (${airportCode})`
      } else {
        const isVn = props.config.locale === 'vi'
        const cityName = isVn ? airportObj.city : airportObj.cityEnName
        const countryName = isVn ? airportObj.countryName : airportObj.countryEnName
        airportName = `${cityName}${airportObj.countryName === 'Việt Nam' ? '' : `, ${countryName}`} (${airportCode})`
      }
      return airportName
    }

    function resolvePaxFareInfoTicket(paxFareInfoTicket) {
      const { paxInfo } = paxFareInfoTicket
      const paxInfoGroup = groupBy(paxInfo, item => item.paxType)
      const res = Object.keys(paxInfoGroup).reduce((result, key) => {
        result[key] = {
          paxType: key,
          amountPassenger: paxInfoGroup[key].length,
          netPrice: paxInfoGroup[key].reduce((netPrice, item) => netPrice + item.netPrice, 0),
          discount: paxInfoGroup[key].reduce((discount, item) => discount + item.discount, 0),
          discountPostpaid: paxInfoGroup[key].reduce((discountPostpaid, item) => discountPostpaid + item.discountPostpaid, 0),
          fee: paxInfoGroup[key].reduce((fee, item) => fee + item.fee, 0),
          feeService: paxInfoGroup[key].reduce((feeService, item) => feeService + item.feeService, 0),
          tax: paxInfoGroup[key].reduce((tax, item) => tax + item.tax, 0),
          total: paxInfoGroup[key].reduce((total, item) => total + item.total, 0),
        }
        return result
      }, {})

      return Object.values(res)
    }

    // tính tổng tiền của paxFareInfoTicket
    const sumTotalArray = paxFareInfoTicket => {
      if (!paxFareInfoTicket) return null
      return resolvePaxFareInfoTicket(paxFareInfoTicket).reduce((sum, item) => sum + item.total, 0)
    }

    function getPricesByPriceQuotes(quoteList, paxLists, ancillaryServices) {
      const result = []
      for (const priceQuote of quoteList) {
        const amount = priceQuote.appliedPassengers.length
        const paxType = priceQuote.appliedPassengers[0].paxType

        const ancillaryPaxType = ancillaryServices.filter(ancillary => {
          const pax = paxLists.find(pax => pax.paxId === ancillary.paxId)
          return pax?.paxType === paxType
        })
        const ancillaryPrice = {
          netPrice: props.config.showAncillaries ? 0 : ancillaryPaxType.reduce((sum, item) => sum + item.basePrice, 0),
          tax: props.config.showAncillaries ? 0 : ancillaryPaxType.reduce((sum, item) => sum + item.totalTax, 0),
          total: props.config.showAncillaries ? 0 : ancillaryPaxType.reduce((sum, item) => sum + item.totalPrice, 0),
        }

        result.push({
          paxType,
          flight: priceQuote.bookingInfos ? priceQuote.bookingInfos.map(item => `${getAirportName(item.departure)} - ${getAirportName(item.arrival)}`).join(', ') : '',
          departureDate: convertISODateTime(priceQuote.bookingInfos[0].departureDate, priceQuote.bookingInfos[0].departureTimezone).ISOdatetime,
          segmentId: priceQuote.bookingInfos ? priceQuote.bookingInfos.map(item => `${item.segmentId}`).join(', ') : '',
          amountPassenger: amount,
          fareBasisCode: priceQuote.fareInfos.map(fare => fare.fareBasisCode).join(', '),
          expirationTime: ticketData.value?.source === '1G' ? convertISODateTime(priceQuote.expirationTime).timeDate : convertISODateTime(priceQuote.expirationTime).timeDate,
          lastDateToPurchase: convertISODateTime(priceQuote.lastDateToPurchase).timeDate,
          feeService: (priceQuote.feeService || 0) * amount,
          netPrice: (priceQuote.basePriceTicket || priceQuote.basePrice) * amount + ancillaryPrice.netPrice,
          tax: (priceQuote.totalTaxesTicket || priceQuote.totalTaxes) * amount + ancillaryPrice.tax,
          total: (priceQuote.totalPriceTicket || priceQuote.totalPrice) * amount + ancillaryPrice.total,
        })
      }
      const groupby = Object.values(groupBy(result, ({ flight, segmentId }) => `${segmentId}|${flight}`))
        .sort((a, b) => Number(a[0].segmentId) - Number(b[0].segmentId))
      const res = groupby.map(item => ({
        trip: item[0].flight,
        departureDate: item[0].departureDate,
        paxFare: item,
        total: item.reduce((a, c) => a + c.total, 0),
      }))
      return res
    }

    function convertBookingDataToFare(bookingData) {
      if (bookingData.source === 'F1') {
        // const departureTime = bookingData.itineraries[0][0].departure || null
        const passengerGroupBy = groupBy(bookingData.paxLists, item => item.paxType)
        const passenger = Object.keys(passengerGroupBy).map(key => `${root.$t(`reservation.${key}`)} x${passengerGroupBy[key].length}`).join(', ')
        const fareTotal = bookingData.paxFareInfoTicket.reduce((sum, paxFareInfoTicketItem) => sum + paxFareInfoTicketItem.paxInfo.reduce((s, pax) => s + pax.total, 0), 0)
        return [{
          trip: bookingData.itineraries.map(itinerary => itinerary.map(segment => `${getAirportName(segment.departure.iataCode)} - ${getAirportName(segment.arrival.iataCode)}`).join(', ')).join(', '),
          fareInfoTicket: [{
            total: fareTotal,
            passenger,
          }],
          total: fareTotal,
        }]
      } if (bookingData?.paxFareInfoTicket?.length) {
        return bookingData.paxFareInfoTicket.map(paxFareInfoTicketItem => {
          const paxFare = resolvePaxFareInfoTicket(paxFareInfoTicketItem)
          return {
            trip: paxFareInfoTicketItem.trips.map(trip => `${getAirportName(trip.departure)} - ${getAirportName(trip.arrival)}`).join(', '),
            departureDate: convertISODateTime(paxFareInfoTicketItem?.trips[0]?.departureDate, paxFareInfoTicketItem?.trips[0]?.departureTz).ISOdatetime,
            paxFare,
            total: paxFare.reduce((a, cur) => a + cur.total, 0),
          }
        })
      }
      if (bookingData?.priceQuotes?.length) {
        return getPricesByPriceQuotes(bookingData.priceQuotes, bookingData.paxLists, bookingData.ancillaryServices)
      }
      if (!bookingData?.priceQuotes?.length && bookingData?.historicalPriceQuotes?.length) {
        return getPricesByPriceQuotes(bookingData.historicalPriceQuotes, bookingData.paxLists, bookingData.ancillaryServices)
      }
      return null
    }

    const fareBookingDataArray = ref([])
    const ancillariesBookingDataArray = ref([])
    const totalAncillaryPriceRaw = ref(0) //  tổng tiền dịch vụ trong booking
    const totalAncillaryPriceCustom = ref(0) //  tổng tiền dịch vụ chênh khi sửa
    const totalAncillaryPrice = computed(() => totalAncillaryPriceRaw.value + totalAncillaryPriceCustom.value) //  tổng tiền dịch vụ hiển thị (có thể chỉnh sửa)
    watch(() => [combinateRef.value, ticketData.value, props.config.showAncillaries], ([isCombinate, ticketData]) => {
      const bookings = [ticketData]
      if (isCombinate && ticketData.roundtripBooking) {
        bookings.push(ticketData.roundtripBooking)
      } else if (isCombinate && ticketData.multiCityBookings) {
        bookings.push(...ticketData.multiCityBookings.filter(booking => !['CANCEL'].includes(booking?.status)))
      }
      bookings.sort((a, b) => a?.id - b?.id ? 1 : -1)

      if (!props.isConfig) {
        fareBookingDataArray.value = bookings
          .map(booking => convertBookingDataToFare(booking))
          .flatMap(a => a)
          .filter(Boolean)
          .sort((a, b) => (new Date(a.departureDate)) - (new Date(b.departureDate)))

        ancillariesBookingDataArray.value = bookings.filter(booking => booking?.source?.includes('1S'))
          .map(item => ({
            ...item,
            ancillaryTotal: item.ancillaryServices.reduce((sum, a) => sum + a.totalPrice, 0),
          }))
          .sort((a, b) => (new Date(a.departureDate)) - (new Date(b.departureDate)))
        totalAncillaryPriceRaw.value = bookings.reduce((total, b) => total + b.ancillaryServices.reduce((sum, item) => sum + item.totalPrice, 0), 0)
      } else {
        fareBookingDataArray.value = FARE_BOOKING_DATA_ARRAY_SAMPLE
        ancillariesBookingDataArray.value = []
      }
    }, { immediate: true, deep: true })

    // Sửa tiền dịch vụ
    const handleChangeAncillaryPrice = text => {
      totalAncillaryPriceCustom.value = Number(text) - totalAncillaryPriceRaw.value
    }

    // Ẩn / hiện Tổng của các bảng
    const showTotalPerTrip = computed(() => fareBookingDataArray.value.flat().length > 1)

    const ancillaryTotal = computed(() => ancillariesBookingDataArray.value.reduce((sum, item) => sum + item.ancillaryTotal, 0))

    // Tổng tiền cuối cùng
    const sumAll = computed(() => fareBookingDataArray.value?.flat()?.reduce(
      (a, c) => a + (c?.total || 0),
      props.config.showAncillaries ? ancillaryTotal.value : 0,
    ) + (totalAncillaryPrice.value || 0))

    const handleChangeSumAll = text => {
      const difTotal = Number(text) - sumAll.value
      fareBookingDataArray.value.forEach((booking, index, array) => {
        const difPerBooking = Math.floor(difTotal / array.length)
        booking.total += difPerBooking
        const numPax = booking.paxFare
          .filter(pax => pax.paxType !== 'INFANT')
          .reduce((sum, pax) => sum + pax.amountPassenger, 0)
        booking.paxFare
          .filter(pax => pax.paxType !== 'INFANT')
          .forEach(pax => {
            const difPerPax = Math.floor((difPerBooking * pax.amountPassenger) / numPax)
            pax.netPrice += difPerPax
            pax.total += difPerPax
          })
      })
    }

    const handleChangeBookingTotal = (text, index) => {
      const booking = fareBookingDataArray.value[index]
      const difPerBooking = Number(text) - booking.total
      booking.total = Number(text)
      const numPax = booking.paxFare
        .filter(pax => pax.paxType !== 'INFANT')
        .reduce((sum, pax) => sum + pax.amountPassenger, 0)
      booking.paxFare
        .filter(pax => pax.paxType !== 'INFANT')
        .forEach(pax => {
          const difPerPax = Math.floor((difPerBooking * pax.amountPassenger) / numPax)
          pax.netPrice += difPerPax
          pax.total += difPerPax
        })
    }

    const handleChangePax = (text, index, paxType, type, isChangeFareTicket = false) => {
      const booking = fareBookingDataArray.value[index]
      if (isChangeFareTicket) {
        const pax = booking.fareInfoTicket.find(pax => pax.passenger === paxType)
        pax[type] = Number(text)
        if (type === 'total') {
          pax.netPrice = pax.total - pax.tax
        } else {
          pax.total = pax.netPrice + pax.tax
        }
        booking.total = booking.fareInfoTicket.reduce((sum, pax) => sum + pax.total, 0)
      } else {
        const pax = booking.paxFare.find(pax => pax.paxType === paxType)
        pax[type] = Number(text) * pax.amountPassenger
        if (type === 'total') {
          pax.netPrice = pax.total - pax.tax
        } else {
          pax.total = pax.netPrice + pax.tax
        }
        booking.total = booking.paxFare.reduce((sum, pax) => sum + pax.total, 0)
      }
    }

    const handleChangeAncillarySum = (text, index) => {
      const booking = ancillariesBookingDataArray.value[index]
      const difPerBooking = Number(text) - booking.ancillaryTotal
      booking.ancillaryServices.forEach((ancillary, index, array) => {
        const difPerAncillary = Math.floor(difPerBooking / array.length)
        ancillary.basePrice += difPerAncillary
        ancillary.totalPrice += difPerAncillary
      })
    }

    const handleChangeAncillaryItem = (text, index, itemId) => {
      const booking = ancillariesBookingDataArray.value[index]
      const ancillary = booking.ancillaryServices.find(item => item.id === itemId)
      ancillary.totalPrice = Number(text)
      ancillary.basePrice = ancillary.totalPrice - ancillary.totalTax
    }

    const currentTheme = ref(0)

    const fontSizeArr = ['14px', '16px', '18px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px']
    const sizeStyle = Quill.import('attributors/style/size')
    sizeStyle.whitelist = [...fontSizeArr]
    Quill.register(sizeStyle, true)

    const fontStyleArr = ['Lato', 'arial', 'tinos', 'inconsolata', 'roboto', 'montserrat', 'sofia']
    const fontStyle = Quill.import('formats/font')
    fontStyle.whitelist = fontStyleArr
    Quill.register(fontStyle, true)

    const editorOption = ref({
      theme: 'bubble',
      syntax: false,
      modules: {
        toolbar: [
          [{ font: fontStyleArr }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: fontSizeArr }],
          [{ color: [] }, { background: [] }],
          ['blockquote', 'code-block'],
          [{ align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
      placeholder: '',
    })

    const agencyInfo = computed(() => store.getters['userStore/getAgencyData'])

    const ticketQRCode = ref('')
    const defaultBankAccount = ref({})

    const isShowAlertBankAccount = ref(false)
    watch(() => [sumAll.value, props.config.showPrice, props.config.bankAccount], ([sumAll, showPrice, bankAccount]) => {
      if (isShowAlertBankAccount.value) return
      const description = ticketData.value ? ticketData.value.bookingCode : props.flights[0].bookingCode
      if (!agencyInfo.value) return
      if (!(agencyInfo.value?.bankAccs?.length ?? agencyInfo.value?.bankAccounts?.length)) {
        if (props.isConfig) {
          Vue.swal({
            title: 'Cảnh báo!',
            text: 'Đại lý chưa cài đặt tài khoản ngân hàng mặc định! Vui lòng liên hệ Admin đại lý.',
            icon: 'warning',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Đóng',
            customClass: {
              confirmButton: 'btn btn-outline-warning',
            },
            buttonsStyling: false,
          })
        } else {
          useToast().toast({
            title: 'messagesList.warning',
            content: 'Đại lý chưa cài đặt tài khoản ngân hàng mặc định! Vui lòng liên hệ Admin đại lý.',
            variant: 'warning',
          })
        }
        isShowAlertBankAccount.value = true
      } else {
        defaultBankAccount.value = bankAccount
        if (!defaultBankAccount.value) return
        const { bankCode, bankAccountNo, bankAccountName } = defaultBankAccount.value
        ticketQRCode.value = `https://img.vietqr.io/image/${bankCode}-${bankAccountNo}-qr_only.jpg?${showPrice ? `amount=${sumAll}&` : ''}addInfo=${description}&accountName=${bankAccountName}`
      }
    }, { immediate: true, deep: true })

    const { findBank } = useBankAccounts()
    function resolveBankCode(bankCode) {
      const bankInfo = findBank(bankCode)
      return bankInfo ? { bankName: `${bankInfo.value.shortName} (${bankInfo.value.code})`, bankLogo: bankInfo.value.logo } : { bankName: bankCode }
    }

    const agencyAvatar = ref('')
    watch(agencyInfo, async agency => {
      if (agency) {
        agencyAvatar.value = await toDataUrl(agency.avatar)
      }
    }, { immediate: true })

    const qrCodeAvatar = ref('')
    watch(ticketQRCode, async qrCode => {
      qrCodeAvatar.value = await toDataUrl(qrCode)
    }, { immediate: true })

    const bankAvatar = ref('')
    watch(defaultBankAccount, async bankAccount => {
      bankAvatar.value = await toDataUrl(resolveBankCode(bankAccount?.bankCode).bankLogo)
    }, { immediate: true })

    function toggleConfigStatus(flight) {
      const statusOptions = ['PAID', 'HOLD', 'CANCEL']
      const indexBookingStatus = statusOptions.findIndex(i => i === flight.bookingStatus || i === props.config.status)
      flight.bookingStatus = indexBookingStatus + 1 === 3 ? statusOptions[0] : statusOptions[indexBookingStatus + 1]
      // eslint-disable-next-line vue/no-mutating-props
      props.config.status = indexBookingStatus + 1 === 3 ? statusOptions[0] : statusOptions[indexBookingStatus + 1]
    }

    watch(() => props.config.color, color => {
      currentTheme.value = THEMES[color.toLowerCase()]
    }, { immediate: true })

    watch(() => ticketData.value.status, val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.config.status = val
      emit('update:config', props.config)
    }, { immediate: true })

    const newPaxs = ref(props.paxs)
    watch(() => props.paxs, val => {
      newPaxs.value = val
    }, { immediate: true })

    const removePax = index => {
      if (newPaxs.value.length > 1) { newPaxs.value.splice(index, 1) }
    }

    const addPax = index => {
      newPaxs.value = [...newPaxs.value.slice(0, index + 1), { passenger: {} }, ...newPaxs.value.slice(index + 1)]
    }

    function handleResetNote() {
      emit('resetNote')
    }

    function handleResetAgencyInfo() {
      emit('resetAgencyInfo')
    }

    const configTicket = toRefs(props).config

    const tableColumnsPaxFareInfoTicket = computed(() => {
      const arr = ['passenger', 'netPrice', 'tax', 'total']
      return configTicket.value?.showPriceNetFare ? arr : arr.filter(item => ['passenger', 'total'].includes(item))
    })

    const tableColumnsPriceQuotes = computed(() => {
      const arr = ['flight', 'paxType', 'fare', 'tax', 'total']
      return configTicket.value?.showPriceNetFare ? arr : arr.filter(item => ['flight', 'paxType', 'total'].includes(item))
    })

    const tableColumnsFareInfoTicket = computed(() => {
      const arr = ticketData.value.source === 'F1' ? ['passenger', 'total'] : ['passenger', 'netPrice', 'tax', 'total']
      return configTicket.value?.showPriceNetFare ? arr : arr.filter(item => ['total'].includes(item))
    })

    const qrBookingInfo = computed(() => {
      const { paxLists } = ticketData.value
      let airline
      const findTrip = flights.value.find(f => f.segments.some(seg => {
        if (iataCodeIndia.includes(seg?.from) || iataCodeIndia.includes(seg?.to)) {
          airline = seg.airline
          return true
        }
        return false
      }))
      if (!findTrip) return false
      const { bookingCode } = findTrip
      const firstPassengerName = `${paxLists[0]?.lastName}/${paxLists[0]?.firstName}`
      return `${bookingCode}|${firstPassengerName}|${airline}`
    })

    return {
      resolveBookingStatusVariant,
      printSize,
      currentTheme,
      THEMES,
      getLocaleFullName,
      formatCurrency,
      editorOption,

      getAirportName,
      agencyInfo,

      isEmpty,
      toggleConfigStatus,

      agencyAvatar,
      qrCodeAvatar,
      bankAvatar,
      defaultBankAccount,

      getBaggageInBookingDetail,
      resolveBankCode,
      newPaxs,
      removePax,
      addPax,
      handleResetNote,
      handleResetAgencyInfo,
      combinateRef,
      ticketData,
      flights,
      removeFlight,
      addFlight,

      sumTotalArray,
      tableColumnsPriceQuotes,
      getPricesByPriceQuotes,
      resolvePaxFareInfoTicket,
      tableColumnsPaxFareInfoTicket,
      tableColumnsFareInfoTicket,
      fareBookingDataArray,
      ancillariesBookingDataArray,
      airlinesLogoLong,
      ancillaryTotal,
      sumAll,
      showTotalPerTrip,
      resolveAirPnr,
      handleChangeSumAll,
      handleChangeBookingTotal,
      handleChangePax,
      handleChangeAncillarySum,
      handleChangeAncillaryItem,
      totalAncillaryPrice,
      totalAncillaryPriceRaw,
      handleChangeAncillaryPrice,
      qrBookingInfo,
    }
  },

  methods: {
    getAirCraftByIata(code) {
      return this.$store.getters['globalConfig/getAirCraftByIata'](code)
    },
    getAirlineNameByCode(code) {
      return this.$store.getters['globalConfig/getAirlineNameByCode'](code)
    },
    getBaggagesByBookingClass,
    getGroupClass,
    getHandBaggage,
    getFreeBaggage,
  },
}
</script>

<style lang="scss">
  .e-ticket {
    padding: 24px;
    gap: 16px;
    border-radius: 12px;

    &.a4 {
      width: 100%;
      min-width: 100px;
    }

    &.theme-yellow {
      .color-theme {
        color: #efad02;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #f5ef8a !important; // #efad0220;
      }

      .border-theme {
        border: 1px solid #efad02;
      }
    }

    &.theme-red {
      .color-theme {
        color: #e22326;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #F9D3D4 !important;
      }

      .border-theme {
        border: 1px solid #e22326;
      }
    }

    &.theme-green {
      .color-theme {
        color: #45b143;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #DAEFD9 !important;
      }

      .border-theme {
        border: 1px solid #45b143;
      }
    }

    &.theme-blue {
      .color-theme {
        color: #0088FF;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #DDF3FF !important;
      }

      .border-theme {
        border: 1px solid #0088FF;
      }
    }

    &.theme-light_blue {
      .color-theme {
        color: #40B0C9;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #D9EFF4 !important;
      }

      .border-theme {
        border: 1px solid #40B0C9;
      }
    }

    &.theme-black {
      .color-theme {
        color: #343434;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #D6D6D6 !important;
      }

      .border-theme {
        border: 1px solid #343434;
      }
    }

    &.theme-pink {
      .color-theme {
        color: #CD2A7A;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #F5D4E4 !important;
      }

      .border-theme {
        border: 1px solid #CD2A7A;
      }
    }

    &.theme-indigo {
      .color-theme {
        color: #6517F6;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #E0D1FD !important;
      }

      .border-theme {
        border: 1px solid #6517F6;
      }
    }

    &.theme-purple {
      .color-theme {
        color: #6E43C4;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #E2D9F3 !important;
      }

      .border-theme {
        border: 1px solid #6E43C4;
      }
    }

    &.theme-orange {
      .color-theme {
        color: #F47D00;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #FDE5CC !important;
      }

      .border-theme {
        border: 1px solid #F47D00;
      }
    }

    &.theme-teal {
      .color-theme {
        color: #48C995;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #DAF4EA !important;
      }

      .border-theme {
        border: 1px solid #48C995;
      }
    }

    &.theme-gray {
      .color-theme {
        color: #78808A;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #E4E6E8 !important;
      }

      .border-theme {
        border: 1px solid #78808A;
      }
    }

    .text-gray {
      color: #000000;
    }

    // .border-theme {
    //   border-color: currentColor !important;
    // }

    .border-gray {
      border-color: #747783;
    }

    .text-black {
      color: #000;
    }

    .text-red {
      color: #FF0000;
    }

    .flex {
      display: flex;
    }

    .section-agency {
      display: flex;
      padding: 20px;
      gap: 40px;
      // border-radius: 12px;
      // border: 1px solid #E6E6E6;
    }

    .section-flight {
      border-radius: 12px;
      border: 1px solid #E6E6E6
    }

    .segment-content {
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 12px;
      border: 1px solid transparent;
    }

    .segment-content-l {
      // width: 55%;
      min-width: 560px;
      padding: 20px;
      z-index: 1;
      flex: 1;
    }

    .barcode-wrapper {
      display: flex;
      padding: 4px;
      align-items: center;
      align-self: stretch;
      flex-grow: 0;
      flex-shrink: 0;
      background: #fff;

      >img {
        width: 26px;
      }
    }

    .custom-v-line {
      border-left: 4px dashed white;
      margin-left: 8px;
    }

    .segment-content-r {
      max-width: 35%;
      flex-grow: 1;
      padding: 20px;
      z-index: 1;

      >.airline {

        >img.logo {
          height: 44px;
        }
      }
    }

    .group-class {
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 18px;
      letter-spacing: 1px;
      color: #305372;
    }

    .segment-booking-code {
      min-height: 67px;
      border-radius: 8px;
      overflow: hidden;

      >svg {
        top: 0;
        left: 0;
      }

      >.payment-status {
        right: -35px;
        width: 120px;
        padding: 4px 25px 3px;
        font-weight: 700;
        font-size: 8px;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;
        transform: rotate(50deg);

        &.bg-current {
          background-color: currentColor;
        }
      }
    }

    .border-dot {
      border-bottom: 2px dashed;
    }

    .section-table {
      display: grid;
      border: 1px solid #E6E6E6;
      border-radius: 10px;
      overflow: hidden;

      &.pax-table-template-columns {
        grid-template-columns: 10% 40% 50%;
      }

      &.price-table-template-columns {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .border-t {
        border-top: 1px solid #E6E6E6;
      }

      &.table-responsive {
        border-top: 0px;
        border-bottom: 0px;

        tbody {
          border-left: 0px !important;
          border-right: 0px !important;
        }
      }
    }

    .pax-button {
      opacity: 0;
    }

    .section-flight:hover .pax-button,
    .pax-table-template-columns:hover .pax-button {
      opacity: 1;
    }

    .section-note {
      padding: 20px;
      border: 1px solid #E6E6E6;
      border-radius: 8px;
      display: flex;
      gap: 12px;
      align-items: flex-start;
    }

    .text-14px {
      font-size: 14px;
    }

    .text-16px {
      font-size: 16px;
    }

    .text-18px {
      font-size: 18px;
    }

    .text-20px {
      font-size: 20px;
    }

    .text-24px {
      font-size: 24px;
    }

    .text-30px {
      font-size: 30px;
    }

    .rounded-8px {
      border-radius: 8px;
    }

    .gap-4px {
      gap: 4px;
    }

    .gap-8px {
      gap: 8px;
    }

    .gap-12px {
      gap: 12px;
    }

    .gap-16px {
      gap: 16px;
    }

    .p-4px {
      padding: 4px;
    }

    .px-12px {
      padding-left: 12px;
      padding-right: 12px;
    }

    .px-20px {
      padding-left: 20px;
      padding-right: 20px;
    }

    .p-4px-8px {
      padding: 4px 8px !important;
    }

    .p-4px-16px {
      padding: 4px 16px;
    }

    .p-8px-16px {
      padding: 8px 16px;
    }

    .mt-4px {
      margin-top: 4px;
    }

    .my-12px {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .fw-700 {
      font-weight: 700;
    }

    .group {
      .plain {
        color: transparent;

        &:focus,
        &:hover,
        &:active {
          color: initial;

          +.formatter {
            display: none;
          }
        }
      }

      .formatter {
        top: 50%;
        transform: translateY(-50%);
        padding-left: 1em;
      }

      &:hover {
        .plain {
          color: inherit;
        }

        .formatter {
          display: none;
        }
      }
    }

    p,
    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
      margin: 0px;
      padding: 0px;
      line-height: unset;
    }

    .ql-editor {
      overflow: visible;
      padding: 0px !important;

      >* {
        line-height: unset;
      }
    }

    .ql-container {
      font-size: 16px
    }

    .ql-tooltip {
      z-index: 10000;
    }

    .table thead th {
      @extend .bg-theme;
      background-color: white !important;
    }

    #div-value-money {
      display: inline-flex;
      align-items: center;
      margin-bottom: -4px;
      width: 160px;
    }

    #value-money {
      display: inline;
      background-color: transparent;
      border: none;
      width: 100%;
    }
  }

  @import '@core/scss/vue/libs/quill.scss';
</style>
